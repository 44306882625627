import { mapHelper } from "@/utils/utils";

// 状态
const oStatus = [
  {
    text: "未完成",
    value: 0,
  },
  {
    text: "完成",
    value: 1,
  },
];

const { map: oStatusMap, setOps: oStatusOps } = mapHelper.setMap(oStatus);

export { oStatusMap, oStatus, oStatusOps };
